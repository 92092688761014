.checkboxErrorMessage,
.radioButtonsErrorMessage,
.validationCodeErrorMessage,
p.Mui-error {
  font-size: 14px;
  font-weight: 400;
  margin-top: 3px;
  line-height: 16px;
  color: #ff435a;
}

p.Mui-error {
  margin-left: 32px;
}
