html {
  background-color: #f6f7fa;
}

body {
  margin: 0;
}

body,
#CybotCookiebotDialog * {
  font-family: 'Source Sans 3', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji' !important;
}

/* Hide only the redundant elements */
.cybot-cookiebot-declaration {
  display: none;
}

#CybotCookiebotDialog {
  max-width: 1336px !important;
}

.sr {
  border: 0;
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

#CybotCookiebotDialog .cookie-popup__close {
  top: 25px !important;
  left: 8px !important;
  cursor: pointer;
}

@media (max-width: 37.9em) {
  #CybotCookiebotDialog .cookie-popup__close {
    top: 1.5rem !important;
    left: auto !important;
  }
}

.MuiInputBase-root:not(.MuiInputBase-sizeSmall) {
  height: 48px;
}

/* Fix issues in IE11 >>> */
@media screen and (-ms-high-contrast: none) {
  .MuiInputLabel-shrink {
    background: white;
    margin-top: -5px;
    margin-left: -5px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .MuiOutlinedInput-input {
    margin-top: -3px !important;
    margin-bottom: 3px !important;
  }

  .MuiInputAdornment-positionEnd,
  .MuiSelect-icon {
    margin-top: -2px !important;
    margin-bottom: 2px !important;
  }

  .MuiInputAdornment-positionStart {
    margin-top: -4px !important;
    margin-right: 12px !important;
    margin-bottom: 2px !important;
  }

  .MuiInputAdornment-positionStart .MuiAutocomplete-input {
    margin-top: 3px !important;
  }

  legend {
    float: left;
  }

  .MuiDialog-paper {
    min-width: 720px !important;
  }

  .header-container {
    z-index: -1;
  }
}

.consent ol {
  list-style-type: none;
}

.consent li li {
  margin-top: 10px;
}

.consent .main-point {
  padding-left: 20px;
}

/* <<< Fix issues in IE11 */

a {
  color: #52ae30;
  text-decoration: none;
}

.alert {
  display: flex;
  border-radius: 30px;
  padding: 6px 2.5rem;
  background-color: #fff8db;
}

.alert-icon {
  margin-right: 12px;
  display: flex;
  padding: 14px 0;
}

.alert-message {
  padding: 15px 0;
}

div[role='radiogroup'] {
  padding-top: 8px;
  padding-bottom: 8px;
}

@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./assets/fonts/SourceSans3/SourceSans3-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(./assets/fonts/SourceSans3/SourceSans3-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Squad';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Squad'), url(./assets/fonts/Squad/squad-heavy.otf) format('opentype');
}
@font-face {
  font-family: 'Squad';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Squad'), url(./assets/fonts/Squad/Squad-Regular.otf) format('opentype');
}

.cookiebot-change-consent {
  display: none;
  height: 4.375rem;
  width: 4.375rem;
  position: fixed;
  left: 1rem;
  bottom: 1rem;
  background-color: #52ae30;
  border-radius: 100%;
  backface-visibility: hidden;
  z-index: 999;
}

.cookiebot-change-consent a {
  display: block;
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 100%;
}

.cookiebot-change-consent a:before {
  content: '';
  height: 1.875rem;
  width: 1.875rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url(./assets/images/cookie_renew.svg);
  background-size: cover;
  background-repeat: no-repeat;
}

@media (max-width: 767px) {
  .cookiebot-change-consent {
    height: 3.125rem;
    width: 3.125rem;
    left: 0.5rem;
    bottom: 0.5rem;
  }

  .cookiebot-change-consent a:before {
    height: 1.5rem;
    width: 1.5rem;
  }
}
